@import "_default.scss_include-mixins";

footer {

	float:left;
	width:100%;
	overflow:hidden;

	.mobile {

		float:left;
		width:100%;
		margin-top:40px;
		line-height:25px;

		.top {
			background-color:#221DF6;
			padding:20px;

			a {
				color:#fff;
				text-decoration:none;

				&.active {
					text-decoration: underline;
				}
			}

		}
		.bottom {
			background-color:#00ff01;
			padding:20px;
			color:#000;

			a {
				color:#000;
			}

		}		

	}

	.inside {
		float:left;
		width:100%;
		margin-top:5vw;

		/*
		Top ****************************
		Top ****************************
		Top ****************************
		Top ****************************
		*/
		.top {
			float:left;
			position:relative;
			width:100%;
			padding-top:1.5vw;
			padding-bottom:1.5vw;

			.logo {
				position:absolute;
				top:50%;
				left:-10%;
				transform:translate(0,-50%);

				.logo-payoff {
					color:#fff;
					font-size:2.5vw;
					line-height:2.5vw;
				}

				.logo-brand {
					color:#fff;
					font-size:1.5vw;
					line-height:1.5vw;
				}

			}

			.bckgrnd {
				position:absolute;
				top:0;
				left:0;
				height:100%;
				width:120%;
				margin-left:-10%;
				background-color:#221DF6;
			}

			nav {

				a {
					color:#fff;
					text-decoration:none;
					font-size: 1.2vw;
					line-height:2.2vw;

					&.active {
						text-decoration:underline;
					}
				}

			}
		}

		/*
		Bottom ****************************
		Bottom ****************************
		Bottom ****************************
		Bottom ****************************
		*/
		.bottom {
			float:left;
			width:100%;
			color:#333;
			padding:20px;
			padding-bottom:4vw;

			a {
				color:#333;
			}
		}

	}

}