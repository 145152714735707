@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	h1 {
		font-size:5vw;
		line-height:4.5vw;
		color:#0033FF;
		padding:0;
		margin:0;
		padding-bottom:1vw;

		@include md {
			font-size:30px;
			line-height:30px;
			padding-bottom:15px;
		}

		&.middle {
			text-align:center;
		}
	}

	h2 {
		font-size:1.8vw;
		color:#00FF01;
		padding:0;
		margin:0;

		@include md {
			font-size:20px;
		}

	}

	table {
		float:left;
		width:100%;
		color:#000;
		border-collapse:collapse;

		tr {
			border-bottom:1px solid #ccc;

			td {
				padding:10px;
			}
		}

	}

	ul  {
		margin:0;
		padding:0;
	}

	ul li {
		// list-style: none;
		// margin:0;
		// padding:0;
	}

	p,ul li,ol li,.p {
		padding:0;
		margin:0;
		margin-top:0.5vw;
		font-size: 1.8vw;
		line-height:2.8vw;
		color:#FB0100;

		@include md {
			font-size:16px;
			line-height:25px;
		}

		a {
			color:blue;
		}

	}

	ul {
		margin-left:2vw;

		&.color-blue {
			li {
				color:#221DF6 !important;
			}
		}
	}

	ol {
		margin-left:2vw;

		&.color-blue {
			li {
				color:#221DF6 !important;
			}
		}
	}

}