@import "_default.scss_include-mixins";

body {
	font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
	background-color:#fff;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

/*
Rotate
*/
.rotate {
	transform:rotate(-3deg);
}

.rotate-extra {
	transform:rotate(-6deg);
}

.rotate-backwards {
	transform:rotate(4deg);
}

/*
Logo
*/
.logo {

	.logo-payoff {
		float:left;
		text-align:right;
		font-size:4vw;
		line-height:3vw;
		font-weight:bold;
		color:#221DF6;
	}

	.logo-brand {
		float:left;
		margin-left:4vw;
		margin-top:1.5vw;
		font-size:3vw;
		line-height:2vw;
		color:#FE700C;
	}
}

/*
Products
*/
.product-box {
	float:left;
	width:100%;

	@include md {
		width:70%;
		margin-left:15%;
		margin-bottom:20px;
	}

	.image {
		float:left;
		position:relative;
		width:100%;

		.packaging {
			float:left;
			top:0;
			left:0;
			width:100%;
			z-index:1;

			img {
				position:relative;
				max-width:80%;
				margin-left:15%;
				transform:rotate(10deg);
			}

		}

		.background {
			position:absolute;
			bottom:2.5vw;
			width:100%;
			height:200px;
			transform:rotate(-6deg);
			z-index:-5;

			@include md {
				height:100px;
				bottom:20px;
			}

			&.orange {
				background-color:#FE700C;
			}
			&.green {
				background-color:#00FF01;
			}
			&.red {
				background-color:#FB2200;
			}

		}
	}

	.info {
		float:left;
		width:100%;
		text-align:center;

		color:#000;

		h2 {
			padding:0;
			margin:0;
			font-size:2vw;

			@include md {
				font-size:20px;
				line-height:30px;
			}

		}

		p {
			float:left;
			width:100%;
			margin:0;
			padding:0;
			margin-bottom:1vw;
			font-size:1vw;
			line-height:1.5vw;

			@include md {
				font-size:16px;
				line-height:20px;
				margin-bottom:10px;
			}

			&.price {
				font-weight:bold;
			}

		}

		.button {
			font-weight:bold;
			font-size:1.2vw;

			@include md {
				font-size:16px;
				line-height:25px;
			}

			.label {
				padding-left:1vw;
				padding-right:1vw;
			}
		}

	}
}