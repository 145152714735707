@import "_default.scss_include-mixins";	 

header {

	.mobile {
		float:left;
		width:100%;
		padding:20px;
		padding-left:0;
		padding-right:0;

		.logo {
			float:left;
			width:auto;
		}

		.checkout {
			position:fixed !important;
			right:70px;
			top:20px;
			width:40px;
			height:34px;
			z-index:10;

			svg {
				.st0{
					fill:#FFFFFF;
				}
				.st1{
					fill:#FFFFFF;
					stroke:#FF325D;
					stroke-width:20px;
					stroke-miterlimit:10;
				}
			}

			span {
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				font-size:20px;
				line-height:20px;
				font-weight:bold;
				color:#221DF6;

				@include md {
					font-size:15px;
					line-height:15px;
				}

			}

		}

		.menu {
			position:fixed !important;
			right:20px;
			top:20px;
			position:relative;
			width:40px;
			cursor:pointer;
			border:1px solid #fff;
			z-index:10;

			.lines {
				float:left;
				width:100%;

				.line {
					float:left;
					margin-top:0;
					width:100%;
					height:8px;
					background-color:#00FF01;
					border-radius:5px;

					&.line-1 {
						margin-top:0;
					}
					&.line-2 {
						margin-top:5px;
						margin-left:10%;
						width:80%;
					}
					&.line-3 {
						margin-top:5px;
					}
				}
			}
		}
	}

	/*
	Menu: Overlay
	*/
	.menu-overlay {
		position:fixed;
		top:0;
		right:-40%;
		width:40%;
		height:100%;
		background-color:#FF325D;
		padding:4vw;
		padding-top:5vw;
		z-index:20;
		overflow:scroll;

		.close {
			position:absolute;
			top:2vw;
			right:2vw;
			width:3.5vw;
			cursor:pointer;

			.lines {
				float:left;
				width:100%;

				.line {
					position:absolute;
					margin-top:0;
					width:100%;
					height:10px;
					background-color:#00FF01;
					border-radius:1vw;

					&.line-1 {
						margin-top:1vw;
						transform:rotate(15deg);
					}
					&.line-2 {
						margin-top:1vw;
						transform:rotate(-40deg);
					}
				}

			}

		}

		.inside {
			float:left;

			nav {
				float:left;
				width:100%;

				a {
					float:left;
					width:100%;
					font-size:2.8vw;
					line-height:4.2vw;
					color:#FEF200;
					text-decoration:none;
					font-weight:bold;

					&.blue {
						color:#221DF6;
					}
				}
			}

		}

	}

	/*
	Rest
	*/
	float:left;
	margin-top:2vw;
	width:100%;

	.logo {
		float:left;
		width:100%;
		margin-left:4vw;

		&.small {

			.logo-payoff {
				font-size:2vw;
				line-height:2vw;
			}

			.logo-brand {
				margin-left:3vw;
				font-size:1vw;
				margin-top:1.3vw;
			}

		}

		.logo-payoff {
			font-size:6vw;
			line-height:5vw;
		}

		.logo-brand {
			font-size:3.2vw;
			margin-top:3.8vw;
		}

	}

	.header-fixed {
		position:fixed;
		right:2vw;
		top:2vw;

		.checkout {
			position:relative;
			float:left;
			width:80px;
			height:70px;

			svg {
				.st0{
					fill:#FFFFFF;
				}
				.st1{
					fill:none;
					stroke:#FF325D;
					stroke-width:1.5vw;
					stroke-miterlimit:10;
				}
			}

			span {
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				font-size:1.5vw; 
				font-weight:bold;
				color:#221DF6;

			}

		}

		.menu {
			float:left;
			position:relative;
			margin-left:1vw;
			width:3.5vw;
			cursor:pointer;
			border:1px solid #fff;

			.lines {
				float:left;
				width:100%;

				.line {
					float:left;
					margin-top:0;
					width:100%;
					height:10px;
					background-color:#00FF01;
					border-radius:1vw;

					&.line-1 {
						margin-top:0;
					}
					&.line-2 {
						margin-top:0.5vw;
						margin-left:10%;
						width:80%;
					}
					&.line-3 {
						margin-top:0.5vw;
					}
				}
			}
		}
	}

}