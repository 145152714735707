@import "_default.scss_include-mixins";

.button-1 {

	display:inline-block;

	padding:10px;
	padding-left:2vw;
	padding-right:2vw;
	background-color:#FEF200;
	border-radius:1.5vw;
	cursor:pointer;

	transition:background-color 0.3s;

	text-decoration:none;

	@include md {
		padding:5px;
		padding-left:10px;
		padding-right:10px;
	}

	&.bckgrnd-rose {
		background-color:#FF325D;
	}

	.inside {

		float:left;
		width:100%;

		.label {
			float:left;
			width:100%;
			text-align:center;
			font-size:1.5vw;
			color:#FB0100;
			font-weight:700;

			@include md {
				font-size:16px;
				line-height:25px;
			}

		}

	}

	&:hover {
		background-color:#FF6400;

		.label {
			color:#fff;
		}
	}

};