@import "_default.scss_include-mixins";

.color-white {
	color:#fff !important;
}

.color-black {
	color:#000 !important;
}

.color-yellow {
	color:#FEF200 !important;
}
.color-green {
	color:#00FF01 !important;
}
.color-orange {
	color:#FE700C !important;
}
.color-blue {
	color:#221DF6 !important;
}
.color-red {
	color:#FB2200 !important;
}
.color-rose {
	color:#FF325D !important;
}

.backgroundcolor-yellow {
	background-color:#FEF200 !important;
}
.backgroundcolor-green {
	background-color:#00FF01 !important;
}
.backgroundcolor-orange {
	background-color:#FE700C !important;
}
.backgroundcolor-blue {
	background-color:#221DF6 !important;
}
.backgroundcolor-red {
	background-color:#FB2200 !important;
}
.backgroundcolor-rose {
	background-color:#FF325D !important;
}