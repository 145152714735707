@import "_default.scss_include-mixins";

.page.content--home {

	#contact {
		@include md {
			width:88%;
			margin-left:5%;
		}
	}

	/*
	Contact: Form
	*/
	.form-input {
		font-size:1.5vw !important;
		padding:1vw !important;
		border:0 !important;

		&.form-input-contact_name {
			background-color:#FEF200;
			color:#000;
		}
		&.form-input-contact_email {
			background-color:#FF325D;
			color:#fff;
		}
		&.form-input-contact_message {
			background-color:#FE700C;
			color:#fff;
		}
	}

	/*
	Contact: Buttons
	*/
	.buttons {
		float:left;
		width:100%;

		margin-top:3vw;
		text-align:center;

		a {
			
			display:inline-block;
			text-decoration:none;
			margin-left:2vw;
			margin-right:2vw;

			.icon {
				float:left;
				position:relative;
				width:4vw;
				height:4vw;
				border-radius:50%;
				background-color:#FEF200;

				@include md {
					width:40px;
					height:40px;
				}

				svg {
					position:absolute;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					fill:#FE700C;
					width:2vw;
					height:2vw;

					@include md {
						width:20px;
						height:20px;
					}
				}
			}

			.label {
				float:left;
				margin-left:2vw;
				margin-top:0.6vw;
				font-size:2vw;
				font-weight:bold;
				color:#221DF6;

				@include md {
					margin-top:10px;
					font-size:20px;
				}

				&:hover {
					border-bottom:2px solid #221DF6;
				}
			}

		}
	}	

}