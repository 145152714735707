@import "_default.scss_include-mixins";

.popup--error {
	float:left;
	width::600px;

	@include md {
		width:280px;
	}

	.close {
		transform:rotate(-20deg);
	}

	.content {
		float:left;
		width:100%;
		color:#000;
		background-color:#fff;
		padding:20px;
		text-align:left;

		@include md {
			padding:10px;
		}

		p {
			font-size:1.5vw;
			line-height:2vw;

			@include md {
				font-size:20px;
				line-height:30px;
			}

		}

	}

}
	