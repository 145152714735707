@import "_default.scss_include-mixins";

.page.content--winkelmand {

	.cart {
		float:left;
		width:100%;
		margin-top:2vw;
		margin-bottom:2vw;
		overflow:auto;

		@include md {
			margin-top:20px;
			margin-bottom:20px;
		}

		.cart-item-mobile {
			float:left;
			width:100%;
			padding:10px;
			border-bottom:1px solid #ccc;
			text-align:center;

			&.js-note-sendcosts-1 {
				padding:5px;
				p {
					font-size:12px !important;
					padding:0;
					margin:0;
				}
			}
			&.js-note-sendcosts-2 {
				padding:5px;
				p {
					font-size:12px !important;
					padding:0;
					margin:0;
				}
			}

			&.total { 
				border:none;
			}

			.inside {

				display:inline-block;

				h2 {
					float:left;
					width:100%;
					font-size:25px;
					padding:0;
					margin:0;
				}

				h3 {
					float:left;
					width:100%;
					font-size:16px;
					padding:0;
					margin:0;
				}

				.options {
					float:left;
					margin-top:10px;

					input[type="text"] {
						width:40px;
						padding:5px;
						padding-left:10px;
						padding-right:10px;
						height:100%;
						background-color:#221DF6;
						color:#fff;
						font-size:20px;
						text-align:center;
						font-weight:bold;
					}

				}

				.counter {
					float:left;
					margin-left:10px;
					margin-top:10px;

					.counter-item {
						display:inline-block;
						margin-left:5px;
						position:relative;
						width:30px;
						height:30px;
						background-color:#FF325D;
						border-radius:50%;
						cursor:pointer;

						span {
							position:absolute;
							top:50%;
							left:50%;
							transform:translate(-50%,-50%);
							font-size:1vw;
							line-height:1vw;
							color:#fff;
							font-weight:bold;

							@include md {
								font-size:20px;
							}

						}
					}

				}

				.button {

					float:left;
					margin-left:10px;
					width:30px;
					height:30px;
					cursor:pointer;

					.lines {
						position:relative;
						float:left;
						width:100%;

						.line {
							position:absolute;
							margin-top:0;
							width:100%;
							height:7px;
							background-color:#00FF01;
							border-radius:1vw;

							&.line-1 {
								margin-top:20px;
								transform:rotate(15deg);
							}
							&.line-2 {
								margin-top:20px;
								transform:rotate(-40deg);
							}
						}

					}

				}

				.price {
					float:left;
					margin-left:10px;
					margin-top:10px;
					font-size:22px;
					line-height:22px;
					font-weight:bold;
				}

				&.total {

					h3 {
						font-size:22px;
						line-height:22px;
						
					}
				}

			}
		}

		table {
			float:left;
			width:100%;
			border-collapse: collapse;

				tr.cart-item {
					
					border-bottom:1px solid #00FF01;

					&.total {
						border-bottom:0;
						white-space: nowrap;

						h3 {
							font-size:2vw !important;
							font-weight:bold !important;

							@include md {
								font-size:20px !important;
							}
						}
					}

					td {

						&.width10 {
						//	width:10%;
						}
						&.width20 {
							//width:20%;
						}
						&.width30 {
							//width:30%;
						}
						&.width50 {
							//width:50%;
						}
						
						.inside {
							float:left;
							width:100%;
							padding:10px;
						}


						.image {
							img {
								height:80px;
							}
						}

						input[type="text"] {
							float:left;
							width:50px;
							padding:10px;
							padding-left:10px;
							padding-right:10px;
							height:100%;
							background-color:#221DF6;
							color:#fff;
							font-size:1vw;
							text-align:center;
							font-weight:bold;

							@include md {
								font-size:20px;
							}

						}

						.text {

							float:left;

							h2 {
								float:left;
								width:100%;
								font-size:1.5vw;
								padding:0;
								margin:0;

								@include md {
									font-size:20px;
								}

							}

							h3 {
								float:left;
								width:100%;
								font-size:1vw;
								padding:0;
								margin:0;
								font-weight:normal;

								@include md {
									font-size:20px;
								}

							}

							p {
								padding:0;
								margin:0;
								margin-top:20px;
							}

							&.width-100 {
								width:100%;
							}
						}

						.counter {
							float:left;
							width:70px;

							.counter-item {
								float:left;
								margin-left:5px;
								position:relative;
								width:30px;
								height:30px;
								background-color:#FF325D;
								border-radius:50%;
								cursor:pointer;

								span {
									position:absolute;
									top:50%;
									left:50%;
									transform:translate(-50%,-50%);
									font-size:1vw;
									line-height:1vw;
									color:#fff;
									font-weight:bold;

									@include md {
										font-size:20px;
									}

								}
							}
						}

						.button {

							float:left;
							width:40px;
							cursor:pointer;

							.lines {
								position:relative;
								margin-top:-1vw;
								float:left;
								width:100%;

								.line {
									position:absolute;
									margin-top:0;
									width:100%;
									height:7px;
									background-color:#00FF01;
									border-radius:1vw;

									&.line-1 {
										margin-top:1vw;
										transform:rotate(15deg);
									}
									&.line-2 {
										margin-top:1vw;
										transform:rotate(-40deg);
									}
								}

							}

						}

						.price {
							font-size:1.4vw;
							line-height:1.4vw;
							font-weight:bold;

							@include md {
								font-size:20px;
							}

						}

				}
			
			}

		}

	}

	.marge-form {
		margin-top:2vw;
	}
	/*
	Gegevens
	*/
	.form {
		float:left;
		width:100%;
		margin-top:2vw;

		.cart-input {
			float:left;
			width:100%;
			margin-bottom:1vw;

			label {
				float:left;
				width:100%;
				font-size:1.4vw;
				font-weight:bold;

				@include md {
					font-size:20px;
				}

			}

			input {
				float:left;
				margin-top:0.2vw;
				width:100%;
				background-color:#FEF200;
				padding:20px;
				font-size:1vw; 
				color:#000;
				font-weight:bold;

				@include md {
					font-size:20px;
					padding:10px;
				}

			}

			select {
				float:left;
				margin-top:0.2vw;
				width:100%;
				padding:20px;
				font-size:1vw; 
				background-color:#FF325D;
				color:#fff;

				@include md {
					margin-top:10px;
				}

			}
		}

		.option {
			float:left;
			width:100%;

			.checkbox {
				float:left;
				position:relative;
				width:40px;
				height:40px;
				background-color:#221df6;
				cursor:pointer;

				.checked {
					display:none;
					position:absolute;
					left:50%;
					top:50%;
					transform:translate(-50%,-50%);

					svg {
						width:30px;
						height:30px;
						fill:#00FF01;
					}
				}

				&.active {
					.checked {
						display:block;
					}
				}
			}

			.label {
				float:left;
				margin-left:1vw;
				margin-top:0.4vw;
				font-size:1vw;
				font-weight:bold;

				@include md {
					width:75%;
					margin-left:5px;
					font-size:15px;
				}

			}
		}	


		.button {
			margin-top:1vw;
			transform:scale(1.5);
			font-weight:bold;

			@include md {
				margin-top:20px;
			}
		}
	}
	
}