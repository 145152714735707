@import "_default.scss_include-mixins";

.shop {

	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:#221DF6;
	z-index:100;
	overflow:auto;

	.product {
		float:left;
		width:100%;
	}

	&.open { 
		display:block;
	}

	/*
	Button: Close
	*/
	.close {
		position:absolute;
		top:2vw;
		right:2vw;
		width:3.5vw;
		cursor:pointer;
		z-index:100;

		@include md {
			width:40px;
			right:20px;
			top:20px;
		}

		.lines {
			float:left;
			width:100%;

			.line {
				position:absolute;
				margin-top:0;
				width:100%;
				height:10px;
				background-color:#00FF01;
				border-radius:1vw;

				@include md {
					height:8px;
					border-radius:15px;
				}

				&.line-1 {
					margin-top:1vw;
					transform:rotate(15deg);

					@include md {
						margin-top:5px;
					}

				}
				&.line-2 {
					margin-top:1vw;
					transform:rotate(-40deg);

					@include md {
						margin-top:5px;
					}

				}
			}

		}

	}

	.inside {
		position:absolute;
		top:50%;
		left:50%;
		width:850px;
		min-width:280px;
		text-align:center;
		transform:translate(-50%,-50%);

		@include md {
			position:relative;
			left:0;
			top:0;
			transform:none;
			width:100%;
		}

		h1 {
			float:left;
			padding:0;
			margin:0;
			width:100%;
			text-align:center;
			font-size:3vw;
			color:#FE700C;

			@include md {
				text-align:left;
				margin-left:10px;
				font-size:30px;
				margin-top:20px;
			}
		}

		.option {

			float:left;
			width:100%;
			margin-top:1vw;

			display: flex;

			@include md {
				display:block;
			}

			.left {
				position:relative;
				float:left;
				height:400px;
				// flex: 1;
				width:40%;
				text-align:left;

				h2 {
					margin:0;
					padding:0;
				}

				@include md {
					width:100%;
					height:auto;
				}

				.centre {
					position:absolute;
					width:100%;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);

					@include md {
						position:relative;
						// margin-top:20px;
						margin-bottom:80px;
						top:0;
						left:0;
						transform:none;
					}

					.counter {

						float:left;
						width:100%;

						@include md {
							padding:10px;
						}

						.frm {
							margin-top:20px;
						}

						input[type="text"] {
							float:left;
							width:80px;
							padding-left:10px;
							padding-right:10px;
							height:50px;
							font-size:2vw;
							text-align:center;
							font-weight:bold;
							color:#000;

							@include md {
								font-size:20px;
							}
						}

						.add-or-remove {
							float:left;
							margin-left:1vw;

							.bt {
								float:left;
								position:relative;
								width:40px;
								height:40px;
								border-radius:50%;
								background-color:#fff;
								color:#000;
								font-size:2vw;
								margin-top:0.3vw;
								margin-right:0.3vw;
								font-weight:normal;
								cursor:pointer;

								@include md {
									font-size:20px;
									margin-top:5px;
									margin-left:10px;
								}

								span {
									position:absolute;
									top:50%;
									left:50%;
									transform:translate(-50%,-50%);

									svg {
										fill:#000;
									}
								}
							}
						}
					}

					.weight {

						float:left;
						width:100%;
						margin-top:20px;

						@include md {
							margin-top:0;
							padding:10px;
						}

						.frm {
							float:left;

							@include md {
								margin-top:20px;
							}

							.option {
								float:left;
								width:auto;
								margin-right:1vw;

								.checkbox {
									float:left;
									position:relative;
									width:40px;
									height:40px;
									background-color:#fff;
									cursor:pointer;

									.checked {
										display:none;
										position:absolute;
										left:50%;
										top:50%;
										transform:translate(-50%,-50%);

										svg {
											width:30px;
											height:30px;
											fill:#00FF01;
										}
									}

									&.active {
										.checked {
											display:block;
										}
									}
								}

								.label {
									float:left;
									margin-left: 10px;
									margin-top: 10px;
									font-size: 15px;
									font-weight:bold;

									@include md {
										margin-right:10px;
									}
								}
							}						
						}

					}

					.total {
						float:left;
						width:100%;
						margin-top:20px;

						.left-side {
							float:left;
							width:40%;

							h2 {
								display:inline-block;
								text-align:center;
								padding:0;
								margin:0;
								margin-top:16px;
								font-size:34px;
							}
						}
						.right-side {
							float:right;
							width:60%;

							.button {
								display:inline-block;
								margin-top:13px;
								font-size:25px;						
							}
						}			

					}

				}

			}

			.right {				
				float:right;
				position:relative;
				width:60%;
				height:400px;
				text-align:center;

				@include md {
					width:100%;
					height:auto;
				}

				.image {

					@include md {
						float:left;
						width:100%;
						margin-top:40px;
					}

					img { 
						height:300px;
					}
				}

				.centre {
					position:absolute;
					width:100%;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);

					@include md {
						position:relative;
						// margin-top:20px;
						margin-bottom:80px;
						top:0;
						left:0;
						transform:none;
					}

					.text {

						// @include md {
						// 	display:none;
						// }

						float:left;
						width:100%;

						@include md {
							padding:10px;
							margin-top:20px;
							padding-bottom:80px;
						}

						p {
							font-size:25px;
							line-height:35px;
							color:#fff;
							text-align:left;

							@include md {
								font-size:16px !important;
								line-height:25px !important;
							}

						}
					}

					.order {
						float:left;
						width:100%;
						margin-top:3vw;

						@include md {
							margin-top:20px;
							margin-bottom:20px;
						}

						.button {
							display:inline-block;
							transform:scale(1.3);
						}

					}
				}

			}

		}


	}

}