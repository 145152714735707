@import "_default.scss_include-mixins";

.include.reviews {

	float:left;
	width:100%;

	.review {
		
		position:relative;
		float:left;
		width:100%;
		margin-top:3vw;

		@include md {
			margin-top:40px;
		}

		&.rotate-1 {
			transform:rotate(-10deg);
		}
		&.rotate-2 {
			transform:rotate(-5deg);
		}
		&.rotate-3 {
			transform:rotate(-20deg);
		}
		&.rotate-4 {
			transform:rotate(-90deg);
		}


		&.color-1 {
			.st0 {
				stroke:#FEF200;
			}
		}
		&.color-2 {
			.st0 {
				stroke:#FF325D;
			}
		}
		&.color-3 {
			.st0 {
				stroke:#221DF6;
			}
		}
		&.color-4 {
			.st0 {
				stroke:#00FF01;
			}
		}

		.image {

			svg {
				.st0{
					fill:none;
					stroke-width:0.8vw;
					stroke-miterlimit:10.0045;

					@include md {
						stroke-width:8px;
					}
				}
			}
		}

		.text {
			position:absolute;
			top:50%;
			left:50%;
			width:60%;
			text-align:center;
			font-size:1.5vw;
			line-height:2vw;
			color:blue;
			transform:translate(-50%,-50%);

			@include md {
				font-size:16px;
				line-height:25px;
			}

		}
	}
}