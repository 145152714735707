@import "_default.scss_include-mixins";

.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	background-color:#fb2200;
	z-index:9999;

	.close {
		position:absolute;
		top:20px;
		right:20px;
		width:30px;
		cursor:pointer;
		z-index:10;

		.lines {
			float:left;
			width:100%;
			height:35px;
			height:25px;

			.line {
				position:absolute;
				margin-top:0;
				width:100%;
				height:6px;
				background-color:#00FF01;
				border-radius:1vw;

				&.line-1 {
					margin-top:1vw;
					transform:rotate(15deg);
				}
				&.line-2 {
					margin-top:1vw;
					transform:rotate(-40deg);
				}
			}

		}

	}
	
	.scroller {
		position:relative;
		width:100vw;
		height:100vh;
	}

	.inside {
		position:absolute;
		top: 50%;
		left:50%;
		transform: translate(-50%,-50%);
		text-align:center;

		.break {
			float:left;
			width:100%;
			margin-top:10px;
		}

		ul {
			list-style: none;
		}
	
		a {
			float:left;
			width:100%;
			text-align:center;
			color:#fff;
			font-size:18px;
			line-height:35px;
			font-weight:bold;
			text-decoration:none;
		}

	}

}