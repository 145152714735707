@import "_default.scss_include-mixins";

.form {
	color:#000;

	a {
		color:#000;
	}

	.form-submit {
		display:block;
		position:relative;
		margin-top:20px;
		padding:0.5vw;
		padding-left:2vw;
		padding-right:2vw;
		background-color:#fef200;
		border-radius:50px;

		font-size: 1.5vw;
		color: #fb0100;
		cursor:pointer;
		font-weight:bold;

		@include md {
			font-size:20px;
		}
		
	}
	
	.form-cancel {
		float:right;
		margin:0;
		margin-top:10px;
		margin-right:5px;
		padding:10px 15px;
		padding-right:17px;
		font-size:18px;
		font-weight:bold;
		text-transform:uppercase;
		font-family:'Open Sans',arial;
		transition: all 0.1s ease-in-out;
		background-color:#000;
		color:#fff;
		cursor:pointer;

		&:hover {
			text-decoration:underline;
		};

	}

}

.form .form-row {

	 .label {
	 	float:left;
	 	width:100%;
		font-size:17px;
		font-weight:bold;
		color:#000;
		margin-bottom:5px;
	}

	.upload-info {
		float:left;
		width:100%;
		text-align:center;
		font-size:13px;
	}

	.element-title {
		float:left;
		width:100%;
		color:#000;
		text-align:left;
		margin-bottom:20px;
		font-size:1.2vw;
		font-weight:bold;
		margin:0;
		padding:0;
		margin-bottom:10px;

		@include md {
			font-size:20px;
		}
	}

	.element-row {

		float:left;
		margin-bottom:10px;

		.indicator {
			position:absolute;
			top:0;
			right:0;
			height:100%;
			width:2px;
			background-color:#000;
		}

		label {
			float:left;
			font-size:12px;
			color:#000;
			font-weight:bold;
			margin-bottom:5px;
			margin-top:5px;
		}
	
		.form-input {
			padding:8px 25px;
			// box-shadow: inset 0px 0px 7px -4px rgba(0,0,0,1);
			border:1px solid #ccc;
			font-size: .9vw;
			line-height: 1.6vw;
			background-color:rgba(255,255,255,1);

			@include md {
				font-size: 12px;
				line-height: 20px;
				padding:10px 15px;
			}

		}
	
		.upload {
			
			.left {
				width:200px;
				min-height:109px;
				background-color:#e1e1e1;
				border-radius:4px;
				text-align:center;
			}

		}

		.indicator.ok {
			background-color:#84b50f;
		}

	}

}
	
.thanks {
	font-family:'Open Sans',arial;
	color:#000;
	text-align:center;
}

/*
AKKOORD
*/
.akkoord {
	clear:both;
	float:left;
	width:100%;
	margin-top:10px;
	margin-bottom:10px;
	font-size:13px;
	line-height:20px;
	
	.left {
		float:left;
		width:20px;

		 input[type="checkbox"] {
			opacity: 1;
			/* position: absolute; */
		}

		input[type="checkbox"] + label {
			text-align:center;
			cursor:pointer;
		}

		input[type="checkbox"]:focus + label {
			background-color:transparent;
		}

		input[type="checkbox"] + label div {
			display:inline-block;
			line-height: 16px;
			font-size: 12px;
			height: 16px;
			width: 16px;
			margin:-0px 4px 0 0;
			border: 1px solid black;
			color: transparent;
		}

		input[type="checkbox"]:checked + label div {
			color: black;
		}

	}

	.right {
		float:left;
		width:300px;
	}

}
	
/*
Form specific radiobuttons
*/
.form {
	
	.form-label {
		float:left;
		width:100%;
		margin-bottom:5px;
		font-family:'Open Sans',arial;
		font-weight:bold;
	}

	.radiobutton {
		display:inline-block;
	}
	
	.radiobutton_specific-element {
		float:left;
		width:100%;
		margin-bottom:10px;
		padding-bottom:10px;
		border-bottom:1px solid #ccc;

		 .radiobutton_specific_left {
			float:left;
			width:40px;
		}

		.radiobutton_specific_right {
			float:left;
			margin-left:10px;
			width:450px;
		}

		&:last-child {
			border-bottom:0;
		}

	}	

	/*
	Radiobutton: specific
	*/
	.radiobutton_specific {
		float:left;
		width:22px;
		height:22px;
		margin-top:35px;
		border-radius:50%;
		background-color:#fff;
		border:2px solid #ccc;
		cursor:pointer;

		&.active {
			background-color:#84b50f;
		}

	}

	/*
	Checkbox: specific
	*/
	.checkbox_specific {
		float:left;
		width:36px;
		height:31px;
		background-image:url(../img/static/sprite-icon-checkbox_specific.png);
		background-size:cover;
		background-position:top;
		background-repeat:no-repeat;
		cursor:pointer;

		&.active {
			background-position:bottom;
		}

	}

	.checkbox_specific_label_ready { 
	  float:left;
	  margin-left:10px;
	  margin-top:7px;
	  width:380px;

	  @include sm {
	  	width:70%;
	  }

	}


}	